
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import KnowledgeManagementModal from "@/components/modals/forms/knowledge_management/KnowledgeManagementModal.vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "knowledgeManagementList",
  components: {
    KnowledgeManagementModal,
  },
  setup() {
    const { bus } = useBus();
    const editData = ref(null);
    const knowledgeManagementList = ref([]);

    const create = (data) => {
      editData.value = data ? data : null;
    };

    const edit = (data) => {
      editData.value = data;
    };

    const show = (item) => {
      // Add logic for showing item
    };

    const destroy = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiService.post(
                apiEndpoint.data().VUE_APP_KNOWLEDGEMANAGEMENT_DELETE,
                {
                  id: id,
                }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadData();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting output:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting output",
              type: "error",
            });
          }
        }
      });
    };

    const loadData = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_KNOWLEDGEMANAGEMENT_LIST,
            {}
        );
        knowledgeManagementList.value = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const downloadFile = (fileLink) => {
      const anchor = document.createElement("a");
      anchor.href = apiEndpoint.data().BEE_FILE_URL + fileLink;
      anchor.target = '_blank'; // Open link in the same window
      anchor.download = '';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    onMounted(async () => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      loadData();

      bus.on("loadData", async () => {
        loadData();
      });
    });

    return { editData, knowledgeManagementList, create, edit, show, destroy, downloadFile };
  },
});
