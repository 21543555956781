
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "KnowledgeManagementModal",
  props: {
    data: { type: Object },
    loadData: { type: Function },
  },
  setup(props) {
    const { bus } = useBus();
    const formData = ref({
      id: "",
      updated_by: "",
      created_by: VueCookieNext.getCookie("_user_id"),
      knowledge_file: "",
      component_id: "",
      knowledge_management_type_id: "",
    });

    watch(
      () => props.data,
      (newData) => {
        if (newData) {
          formData.value = {
            ...formData.value,
            ...newData,
          };
        }
      }
    );

    const componentOptions = ref([]);
    const fileTypeOptions = ref([]);

    const uploadFile = (event) => {
      formData.value.knowledge_file = event.target.files[0];
    };

    const schema = object().shape({
      // knowledge_file: string().required("file is required"),
      component_id: number().required("Component is required"),
      knowledge_management_type_id: number().required("File Type is required"),
    });

    const errors = ref({});

    const getComponent = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_COMPONENT_LIST
        );

        componentOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getComponentWiseFileType = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_KNOWLEDGEMANAGEMENT_FILE_TYPE,
          {
            component_id: formData.value.component_id,
          }
        );
        fileTypeOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.type_name,
        }));
      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    watch(
      () => formData.value.component_id,
      (newComponent) => {
        if (newComponent !== "") {
          getComponentWiseFileType();
        }
      }
    );

    const save = async () => {
      try {
        // await schema.validate(formData.value, { abortEarly: false });
        ApiService.setHeaderforImage();
        const formDataObj = new FormData();
        formDataObj.append("knowledge_file", formData.value.knowledge_file);
        formDataObj.append("component_id", formData.value.component_id);
        formDataObj.append("knowledge_management_type_id", formData.value.knowledge_management_type_id);
        formDataObj.append("created_by", formData.value.created_by);

        ApiService.post(
          apiEndpoint.data().VUE_APP_KNOWLEDGEMANAGEMENT_STORE,
          formDataObj
        )
          .then((response) => {
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              DrawerComponent.hideAll();
              bus.emit("loadData", true);
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        formData.value = {
          id: "",
          updated_by: "",
          created_by: VueCookieNext.getCookie("_user_id"),
          knowledge_file: "",
          component_id: "",
          knowledge_management_type_id: "",
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    onMounted(getComponent);

    return {
      formData,
      errors,
      save,
      uploadFile,
      componentOptions,
      fileTypeOptions,
    };
  },
});
